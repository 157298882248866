<template>
  <div class="wrap">
    <el-image class='login-background' :src="background" fit="contain" />
    <el-card class="login-card">
      <b class="login-title">Admin Portal</b>
      <el-input class="username-input" v-model="username" placeholder="Username" />
      <el-input class="password-input" v-model="password" placeholder="Password" show-password />
      <el-button class="login-button" type="primary" @click="loginButtonClick" :loading="loginButtonLoading">Login</el-button>
    </el-card>
  </div>
</template>
<script>
import backgroundImg from '../assets/admin-login-background.png'
import { login } from '../api'

export default {
  data() {
    return {
      username: '',
      password: '',
      background: backgroundImg,
      loginButtonLoading: false
    }
  },
  methods: {
    async loginButtonClick() {
      if (this.username && this.password) {
        this.loginButtonLoading = true;
        try {
          const token = await login(this.username, this.password);
          localStorage.token = token.token;
          this.$router.push({ name: 'home' });
        } catch (err) {
          if (err === 'unauthorized') {
            this.$message.error({ center: true, message: 'invalid password' });
          } else {
            this.$message.error({ center: true, message: 'network error' });
          }
        } finally {
          this.loginButtonLoading = false;
        }
      } else {
        this.$message.error({ center: true, message: 'Please enter the complete information！' });
      }
    }
  }
}
</script>
<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #F8FCFF;
}

.login-background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.login-card {
  padding-top: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -185px;
  margin-top: -182px;
  width: 370px;
  height: 364px;
  background: #FFFFFF;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.login-title {
  width: 327px;
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #001644;
  line-height: 20px;
  text-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
}

.username-input {
  width: 300px;
  height: 40px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.password-input {
  width: 300px;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.login-button {
  width: 300px;
  height: 44px;
  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  text-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);

}
</style>
