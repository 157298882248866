<template>
  <div class="home-page">
    <div class="header">
      <div class="left-area">
        <img class="logo" src="../assets/logo.png" />
        <div class="logo-title">Admin Portal</div>
      </div>
      <el-link class="logout" type="info" @click="handleLogout">Log Out</el-link>
    </div>
    <div class="main">
      <div class="table-card">
        <div class="operation-panel">
          <el-button icon="el-icon-plus" type="primary" @click="showOrganizationDialog = true">Add Organization</el-button>
        </div>
        <div class="table-header-section">
          <div class="table-text"><b>{{ userPageData.totalCount }}</b>&nbsp;Users</div>
          <div class="filters">
            <div>
              <span>Status:</span>
              <el-select v-model="filter.status" placeholder="Select">
                <el-option
                  v-for="item in ['All', 'Active', 'Inactive', 'Pending', 'Expired']"
                  :key="item"
                  :label="item"
                  :value="item" />
              </el-select>
            </div>
            <div>
              <span>Organization:</span>
              <el-select v-model="filter.sites" placeholder="Select">
                <el-option
                  v-for="item of ['All', 'Cereb', 'Organization']"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="table-wrap">
          <el-table class="table-content" :data="displayTableData"
            height="auto"
            :cell-style="{ 'font-size': '14px', 'font-weight': 400, 'color': '#888888' }"
            :header-cell-style="{ 'background': '#FAFAFA', 'font-size': '14px', 'font-weight': 500, 'color': 'rgba(0, 0, 0, 0.85)' }"
            empty-text="Empty" v-loading="loadingUsers">
            <el-table-column prop="id" label="ID" width="120" :align="'center'" />
            <el-table-column prop="username" label="Account name" />
            <el-table-column prop="alias" label="Alias" />
            <el-table-column prop="email" label="Email" width="280" />
            <el-table-column prop="organization" label="Organization">
              <template>
                Cereb
              </template>
            </el-table-column>
            <el-table-column prop="status" label="Status">
              <template slot-scope="scope">
                <div class="indicator-wrapper">
                  <div class="status-indicator-inactive"
                    v-if="scope.row.status.substring(0, 7) === 'expired'"></div>
                  <div class="status-indicator-active"
                    v-else-if="scope.row.status === 'active'">
                  </div>
                  <div class="status-indicator-inactive"
                    v-else-if="scope.row.status === 'inactive'"></div>
                  <div class="status-indicator-pending"
                    v-else-if="scope.row.status === 'pending'"></div>
                  <div class="status-text">{{ getStatusText(scope.row.status) }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="dataPoints" label="Max Data Points" align="center" />
            <el-table-column prop="creationTime" label="Creation Time" />
            <el-table-column prop="expirationTime" label="Expiration Time" />
            <el-table-column label="Operations" width="220" fixed="right">
              <template slot-scope="scope">
                <div class="operation-wrap">
                  <el-button class="status-operation-button" @click="updateStatus(scope.row)" type="text" size="small">{{ getStatusButtonText(scope.row.status) }}</el-button>
                  <div class="button-divider"></div>
                  <el-button class="operation-button" @click="handleEdit(scope.row)" type="text" size="small">Edit</el-button>
                  <div class="button-divider"></div>
                  <el-button class="operation-button" @click="handleDelete(scope.row)" type="text" size="small">Delete</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-wrap">
            <el-pagination class="page-index" background layout="prev, pager, next" @current-change="handleCurrentChange" :current-page.sync="currentPage" :pager-count="9" :page-size="pageSize" :total="totalItems" />
          </div>
        </div>
      </div>
    </div>
    <DialogUserEdit :visible.sync="showUserDialog" :value="editFormData" @confirm="dialogEditHandle" />
    <DialogOrganization :visible.sync="showOrganizationDialog" />
  </div>
</template>
<script>
import { EventBus } from "../event-bus.js";
import { MessageBox } from 'element-ui'
import { getAdminPlatformUsers, updateStatus, deleteUser } from '../api'
import DialogUserEdit from '../components/DialogUserEdit.vue'
import DialogOrganization from "../components/DialogOrganization.vue";

export default {
  components: {
    DialogUserEdit,
    DialogOrganization
  },
  data() {
    return {
      showUserDialog: false,
      showOrganizationDialog: false,
      editFormData: {},
      filter: {
        status: 'All',
        organization: 'All'
      },
      userPageData: {
        currPage: 1,
        list: [], //Users
        pageSize: 7,
        totalCount: 0,
        totalPage: 0,
      },
      adminSites: null,
      currentPage: 1,
      pageSize: 7,
      loadingUsers: false
    }
  },
  computed: {
    totalItems() {
      return this.userPageData.totalCount
    },
    displayTableData: function () {
      let currentPageUsers = this.deepClone(this.userPageData.list)
      currentPageUsers.map(user => {
        let expiredTime = new Date(user.expirationTime)
        const nowTime = new Date()
        if (expiredTime < nowTime) {
          user.status = 'expired_' + user.status
        }
        user.creationTime = this.formatTime(user.creationTime)
        user.expirationTime = this.formatTime(user.expirationTime)
        return user
      })
      return currentPageUsers
    }
  },
  methods: {
    getStatusText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(0, 7)
      } else {
        return text
      }
    },
    getStatusButtonText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(8) === 'active' ? 'Deactivate' : 'Activate'
      } else {
        return text === 'active' ? 'Deactivate' : 'Activate'
      }
    },
    defaultErrorHandling(err) {
      console.log(err)
      if (err === 'invalid auth') {
        this.$message.error({ center: true, message: 'invalid salto authentication' });
      } else if (err === 'unauthorized') {
        this.$message.error({ center: true, message: 'login expired' });
        this.logout()
      } else {
        this.$message.error({ center: true, message: 'network error' });
      }
    },
    handleCurrentChange() {
      this.loadUsers()
    },
    async loadUsers() {
      this.loadingUsers = true
      try {
        const userPageData = await getAdminPlatformUsers(null, this.currentPage, this.pageSize)
        this.currentPage = userPageData.currPage
        this.userPageData = userPageData
      } catch (err) {
        this.defaultErrorHandling(err)
      } finally {
        this.loadingUsers = false
      }
    },
    updateStatus(user) {
      let status
      if (user.status.substring(0, 7) !== 'expired') {
        status = user.status === 'active' ? 'inactive' : 'active'
      } else {
        status = user.status.substring(8) === 'active' ? 'inactive' : 'active'
      }
      updateStatus(user.id, status).then(() => {
        this.loadUsers()
      })
    },
    handleEdit(row) {
      let index = (this.userPageData.list || []).findIndex((x) => x.id === row.id);
      let editItem = this.userPageData.list[index]
      Object.assign(this.editFormData, editItem)
      this.showUserDialog = true;
    },
    async handleDelete(row) {
      try {
        await MessageBox.confirm('<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Delete?</div>', '', {
          customClass: "msgBox",
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true
        });
        console.log('delete confirm.');
        await deleteUser(row.id);
        this.$message.success({ center: true, message: 'success' });
        this.loadUsers();
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error({ center: true, message: 'fail' });
        }
        console.log('delete cancel.');
      }
    },
    dialogEditHandle(e) {
      if (e === 'confirm') {
        this.loadUsers()
      }
    },
    async handleLogout() {
      try {
        await MessageBox.confirm('Are you sure you want to log out?', 'Confirm Logout', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        });
        EventBus.$emit('logout');
      } catch (error) {
        // User canceled the logout
      }
    },
    formatTime(preTime) {
      if (preTime === null) {
        return 'null'
      }
      var t = new Date(preTime)
      var time = t.getFullYear() + '-' + (t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() +
        1) + '-' + (t.getDate() < 10 ? "0" + t.getDate() : t.getDate()) + ' ' + (t.getHours() < 10 ?
          "0" + t
            .getHours() : t.getHours()) + ':' + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t
              .getMinutes()) + ':' + (t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds());
      return time
    },
    deepClone(obj) {
      var _obj = JSON.stringify(obj),
        objClone = JSON.parse(_obj);
      return objClone;
    },
  },
  mounted() {
    this.loadUsers()
  }
}
</script>
<style scoped>
.home-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-content::before {
  height: 0px;
}

.indicator-wrapper {
  display: flex;
  align-items: center;
}

.status-text {
  margin-left: 10px;
}

.status-indicator-active {
  width: 6px;
  height: 6px;
  background: #52C41A;
  border-radius: 50%;
}

.status-indicator-inactive {
  width: 6px;
  height: 6px;
  background: #FF4141;
  border-radius: 50%;
}

.status-indicator-pending {
  width: 6px;
  height: 6px;
  background: #CCCCCC;
  border-radius: 50%;
}

.status-indicator-else {
  width: 6px;
  height: 6px;
  background: #ffc107;
  border-radius: 50%;
}


.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  padding: 0 120px;
  height: 64px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  z-index: 1;
}

.header .left-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 46px;
  height: 46px;
}

.logo-title {
  left: 165px;
  font-size: 19px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.logout {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
}

.main {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f8fcff;
}

.table-card {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 38px 0;
}

.table-card .operation-panel {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}

.header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-title {
  font-size: 15px;
  font-weight: 500;
  color: #555555;
  line-height: 22px;
}

.username-text {
  margin-left: 6px;
  font-size: 15px;
  font-weight: 500;
  color: #555555;
  line-height: 22px;
}

.update-button {
  margin-left: 37px;
  width: 88px;
  height: 36px;
  padding-top: 0;
  line-height: 36px;
  font-size: 15px;
  font-weight: 400;
  color: #555555;
}

.table-header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px;
}

.table-header-section .filters {
  display: flex;
  flex-direction: row;
  gap: 64px;
  color: rgba(86, 94, 109, 1);
}

.table-header-section .filters>div>span {
  margin-right: 14px;
}

.table-text {
  margin-left: 48px;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}

.table-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: 0 20px;
  overflow: hidden;
}

.table-content {
  flex: 1;
}

.operation-wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.status-operation-button {
  width: 70px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
}

.operation-button {
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
}

.button-divider {
  width: 1px;
  height: 14px;
  background: #E9E9E9;
  margin-left: 14px;
  margin-right: 14px;
}

.pagination-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 27px;
}
</style>
