<template>
  <el-dialog class='edit-user-dialog' title="Edit User" :visible="visible" @update:visible="onClose" width="500px" @open="onDialogOpen">
    <el-form :model="formData" :rules="rules" ref="editForm" :label-width="'170px'" :label-position="'left'">
      <el-form-item prop="username" label="Account Name:">
        <div>{{ formData.username }}</div>
      </el-form-item>
      <el-form-item prop="alias" label="Alias:">
        <el-input placeholder="Type alias" v-model="formData.alias" />
      </el-form-item>
      <el-form-item label="Expiration Time:">
        <el-date-picker class="full-width" v-model="expirationTimeValue" :align="'left'" type="date" value-format="yyyy-MM-dd" placeholder="Please select a date" :picker-options="pickerOptions" />
      </el-form-item>
      <el-form-item prop="organization" label="Organization:">
        <el-select clearable v-model="formData.organization" placeholder="Select" class="full-width">
          <el-option
            v-for="item of ['Cereb', '7-Eleven']"
            :key="item"
            :label="item"
            :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item required v-if="formData.organization.toLowerCase() == 'cereb'" prop="dataPoints" label="Max Data Points:" placeholder="10">
        <el-input-number class="full-width" type='number' v-model="formData.dataPoints" controls-position="right" :min="1" />
      </el-form-item>
      <el-checkbox v-for="plan of userPlanList" :label="plan" :value="plan === formData.plan" @change="onChangePlan(plan)" :key="plan" />
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">Cancel</el-button>
      <el-button type="primary" @click="submitForm" :loading='isLoading'>Confirm</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { updateUserDetail } from '../api'
import moment from 'moment';
export default {
  props: ['visible', 'value', 'sites'],
  watch: {
    visible(val) {
      if (!val) {
        console.log('visible', val)
      }
    }
  },
  data() {
    const me = this;
    return {
      isLoading: false,
      formData: {
        username: '',
        organization: '',
        alias: '',
        dataPoints: 10,
        plan: '',
      },
      userDetail: {},
      rules: {
        alias: [{
          type: 'string',
          required: true,
          message: 'Alias is required',
          trigger: 'blur'
        }],
        dataPoints: [{
          validator: (rule, value, callback) => {
            if (me.formData.organization === 'cereb') {
              if (!value) {
                callback(new Error('Max Data Points is required'));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 3600 * 1000;
        },
      },
      expirationTimeValue: '',
    };
  },
  computed: {
    userPlanList() {
      switch (this.formData.organization.toLowerCase()) {
        case 'cereb':
          return ['normal', 'premium'];
        case '7-eleven':
          return ['manager', 'staff'];
        default:
          return [];
      }
    }
  },
  methods: {
    onChangePlan(plan) {
      if (this.formData.plan == plan) {
        return;
      }
      this.formData.plan = plan;
    },
    defaultErrorHandling(err) {
      const me = this;
      if (err === 'invalid auth') {
        me.$message.error({ message: 'invalid authentication' });
      } else if (err === 'unauthorized') {
        me.$message.error({ message: 'login expired' });
        me.logout()
      } else {
        me.$message.error({ message: 'network error' });
      }
    },
    onDialogOpen() {
      const me = this;
      Object.assign(me.formData, me.value)
      if (me.formData.expirationTime !== null) {
        me.expirationTimeValue = me.timeConvert(this.formData.expirationTime, 'date')
      } else {
        me.expirationTimeValue = null
      }
    },
    async submitForm() {
      const me = this;
      try {
        const valid = await me.$refs['editForm'].validate();
        if (valid) {
          await me.emitClick('confirm');
        } else {
          console.log('error submit!!');
          return false;
        }
      } catch (error) {
        console.log('validation failed', error);
        return false;
      }
    },
    async emitClick(button) {
      const me = this;
      if (button == 'confirm') {
        me.userDetail = {}
        me.userDetail.id = me.formData.id
        me.userDetail.expirationTime = me.timeConvert(me.expirationTimeValue, 'timestamp')
        me.userDetail.dataPoints = me.formData.dataPoints
        me.userDetail.plan = me.formData.plan
        me.userDetail.alias = me.formData.alias
        me.isLoading = true
        try {
          await updateUserDetail(me.userDetail);
          me.$message.success('success');
          me.$emit('buttonClick', button);
        } catch (err) {
          me.defaultErrorHandling(err);
        } finally {
          me.isLoading = false;
        }
      } else {
        me.$emit('buttonClick', button);
      }
    },
    onClose() {
      this.$emit('update:visible', false);
      this.$refs['editForm'].clearValidate();
    },
    timeConvert(time, toType) {
      if (time === null || time === '' || time === undefined) {
        return null
      }
      let m = moment(time).utcOffset(8)

      if (toType === 'timestamp') {
        return m.format('x')
      } else if (toType === 'date') {
        return m.format('YYYY-MM-DD')
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-user-dialog {
  text-align: left;

  .el-dialog__header {
    padding-top: 30px;
  }

  .el-dialog__body {
    padding-bottom: 0;
    padding-top: 17px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .el-dialog__footer {
    padding-bottom: 30px;
  }
}
</style>