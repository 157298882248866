import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import App from "./App.vue";
import Login from "./pages/Login.vue";
import { setCookie } from "./utils/cookie";
import Home from "./pages/Home.vue";
import { EventBus } from "./event-bus.js";
import "@/assets/site.css"

// element ui 所有可引入组件名: https://github.com/ElemeFE/element/blob/master/components.json
import ElementUI from "element-ui";
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.config.productionTip = false;
const routes = [
  {
    name: "login",
    path: "/",
    component: Login,
  },

  {
    name: "home",
    path: "/",
    component: Home,
  },
];

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  // mode: 'history',
  routes: routes, // (缩写) 相当于 routes: routes
});

function isAuthenticated() {
  // 返回token字符串还是Boolean值？？？ 猜想是token字符串
  return localStorage.token;
}

//
router.beforeEach((to, from, next) => {
  if (!(to.name === "login") && !isAuthenticated()) {
    next({
      name: "login",
    });
  } else if (to.name === "login" && isAuthenticated()) {
    next({
      name: "home",
    });
  } else {
    next();
  }
});

EventBus.$on("logout", () => {
  // 进入登录页面
  setCookie("access-token", "/", ".cereb.ai");
  localStorage.removeItem("token");
  if (router.currentRoute.name !== "login") {
    router.go({
      name: "login",
    });
  }
});

new Vue({
  el: "#app",
  router: router,
  // store,
  render: (h) => h(App),
});

console.log("v20210930build001");
