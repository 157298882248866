import axios from 'axios';
import {
    EventBus
} from "./event-bus.js";

// const API_URL_BASE = 'http://localhost:8080/v1';
// const API_URL_BASE = 'http://localhost:30080/v1';
// const API_URL_BASE = 'http://10.10.21.28:30080/v1'; 
const API_URL_BASE = process.env.VUE_APP_API_URL_BASE;

// TODO 需要修改 ----------------------------------------------------------------------------------------------------
// const API_URL_BASE = 'https://api.cereb.ai/v1';


// const API_URL_BASE = 'http://platform-demo-v2.cereb.ai/#/';
// const TIMEZONE = 'Asia/Hong_Kong'

const instance = axios.create({
    baseURL: API_URL_BASE,
    timeout: 60000,
    withCredentials: true
});

// ==========>请求拦截
instance.interceptors.request.use(config => {
        // 请求时将token加入到headers
        if (localStorage.token) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.token
        }
        return config
    },
    error => {
        console.log('request err: ' + error);
        return Promise.reject(error)
    });

// ==========>响应拦截
instance.interceptors.response.use(response => {
        if ((response.status === 400 || response.status === 403) && response.data) {
            throw response.data
        }
        return response.data
    },
    error => {
        if (error && error.response && error.response.status === 401) {
            // 401 清除localStorage中的token
            // localStorage.removeItem('token');
            // 跳转登录页
            EventBus.$emit('logout');
            return Promise.reject('unauthorized');
        }
        console.log('response err: ' + error);
        return Promise.reject(error)
    });

// function responseHandling(response) {
//     if (response.status === 200) {
//         return response.data
//     } else {
//         throw 'error'
//     }
// }

// function defaultErrorHanding(err) {
//     console.log(err)
//     if (err && err.response) {
//         if (err.response.status === 401) {
//             // 401 清除localStorage中的token
//             localStorage.removeItem('token');
//             throw 'unauthorized'
//         } else if ((err.response.status === 400 || err.response.status === 403) && err.response.data) {
//             throw err.response.data
//         }
//     }
//     throw 'error'
// }

// function getAccessToken() {
//     if (!localStorage.token) {
//         throw 'unauthorized'
//     }
//     return localStorage.token
// }

// function getAuthConfig(token) {
//     return {
//         headers: {
//             'Authorization': 'Bearer ' + token
//         }
//     }
// }

function login(username, password) {
    return instance.post('/login/username', {
        username: username,
        password: password
    })
}


function getAdminPlatformUsers(status, offset, limit) {
    let params = {
        status: status,
        offset: offset,
        limit: limit,
    }
    return instance.get('/admin/adminPlatform/users', {
        params
    })
}

function updateUserDetail(userDetail) {
    return instance.post('/admin/adminPlatform/users', userDetail)
}

function updateStatus(id, status) {
    let params = {
        status: status,
    }
    return instance.post('/admin/users/' + id + '/status', {}, {params})
}

function deleteUser(id) {
    return instance.delete('/admin/adminPlatform/users/' + id)
}

// function updateUser(userData) {
//     return instance.post('/admin/adminPlatform/users', userData)
// }


export {
    login,
    getAdminPlatformUsers,
    updateUserDetail,
    updateStatus,
    deleteUser,
    // updateUser,
}