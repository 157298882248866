<template>
    <el-dialog class='dialog-organization' title="Add Organization" :visible="visible" @update:visible="onClose" width="500px"
        @open="onDialogOpen" top="110px">
        <el-form :model="formData" :rules="rules" ref="editForm" label-position="left" label-width="140px">
            <el-form-item prop="name" label="Name:">
                <el-input placeholder="Type Organization name" v-model="formData.name" />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="onClose">Cancel</el-button>
            <el-button type="primary" @click="submitForm()" :loading='isLoading'>Confirm</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['visible', 'confirm'],
    data() {
        return {
            isLoading: false,
            formData: {
                name: '',
            },
            rules: {
                name: [{
                    type: 'string',
                    required: true,
                    message: 'Organization name is required',
                    trigger: 'blur'
                }],
            },
            expirationTimeValue: '',
        };
    },
    methods: {
        onDialogOpen() {
            this.formData = Object.assign({}, this.value)
        },
        async submitForm() {
            await this.$refs.editForm.validate();
            this.$emit('confirm', this.formData);
            this.onClose();
        },
        onClose() {
            this.$refs.editForm.clearValidate();
            this.$emit('update:visible', false);
        }
    },
};
</script>
<style lang="scss" scoped>
.dialog-organization {
    text-align: left;

    .el-dialog__header {
        padding-top: 30px;
    }

    .el-dialog__body {
        padding-bottom: 0;
        padding-top: 17px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .el-dialog__footer {
        padding-bottom: 30px;
    }
}
</style>